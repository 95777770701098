/* button wrapper */
.get-started-wrapper-blue
{
    margin-top: 20px;
}
.get-started-wrapper-blue p

{
    padding: 14px 40px 14px 40px;
    background-color: transparent;
    background-image: linear-gradient(
        250deg
        , #4E95ED 19%, #03228F 100%);
    border-radius: 30px 30px 30px 30px;
    display: inline-block;
    color: #ffff;
}
.get-started-wrapper-blue a{
    color: #ffff;
}



/* button wrapper */