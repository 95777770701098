.profile_dashboard_title h6 {
	color: #ffff;
}

.profile_tab_wrapper {
	padding-top: 30px;
}

.profile_tab_wrapper .nav-tabs .nav-link {
	border: none !important;
	color: white;
}

.profile_tab_wrapper .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	color: #e22024 !important;
	background-color: unset !important;
	border-color: none !important;
}

.profile_tab_wrapper .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
	border-color: none !important;
}

.profile_logo_card_wrapper {
	color: black;
	background-color: #fff;
	border-radius: 10px;
	/* padding: 18px 14px; */
	font-weight: 600;
	margin: 20px 0px;
	box-shadow: 5px 3px 11px #291d1d;
	width: 100%;
}

.profile_logo_padding_cards {
	padding: 18px 14px;
}

.profile_logo_content_title {
	padding-bottom: 20px;
}

.profile_logo_image_container span {
	color: #242424;
}

.profile_logo_image_container h6 {
	font-size: 12px;
	padding: 15px 0px;
}

.profile_logo_upload_button p {
	background-color: #e22024;
	padding: 5px 15px;
	display: inline-block;
	text-align: center;
	color: #fff;
}

.profile_review_card_wrapper {
	/* background-color:#f5f5f5; */
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.profile_review_card_description {
	padding-top: 20px;
}

.profile_review_card_description p {
	padding: 10px;
}

.profile_review_card_mobile_wrapper {
	display: flex;
	justify-content: center;
}


/* profile venue */


.profile_logo_venue_content h6 {
	padding-bottom: 20px;
}


/* profile venue */


/* contact */

.contact_dashboard_form_wrapper {
	position: relative;
}

.contact_dashboard_form_wrapper .form-control {
	height: 40px;
	padding-left: 40px;
	font-size: 13px;
}

.contact_dashboard_form_icons {
	position: absolute;
	top: 33px;
	left: 15px;
}

.contact_dashboard_form_icons .fa {
	font-size: 19px;
	color: black;
}


/* contact */


/* profile_logo_reservation */


/* profile_logo_reservation */

.profile_logo_reservation {
	padding-bottom: 10px;
}

.profile_logo_reservation h6 {
	padding-bottom: 10px;
}

.profile_time_dropdown_wrapper {
	padding-top: 10px;
	position: relative;
}

select.profile_time_dropdown {
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
}

select+i.profile_time_dropdown_icons {
	position: absolute;
	top: 18px;
	right: 9px;
	color: black;
	font-size: 18px;
}

.profile_dropdown_container {
	font-size: 13px !important;
	position: relative;
}

.profile_time_dropdown_wrapper .form-control:focus {
	color: black;
	background-color: #fff;
	border-color: #a0a0a0;
	outline: 0;
	box-shadow: unset;
}

.profile_time_dropdown_wrapper option {
	font-family: 'Montserrat', sans-serif;
	color: black;
	font-weight: 500;
	font-size: 14px;
}

.profile_time_dropdown_wrapper option:hover {
	cursor: pointer;
}

.profile_dropdown_session_title {
	display: flex;
	align-items: center;
}

.profile_appointment_time_wrapper {
	padding-top: 3px;
}

.profile_appointment_time_wrapper input {
	font-size: 13px;
	color: black;
}

.profile_appointment_add_button {
	display: inline-block;
	padding: 5px;
	background-color: #e22024;
	margin: 10px;
	color: white;
}

.profile_appointment_time_wrapper input:hover {
	cursor: pointer;
}

.fa-trash:hover {
	cursor: pointer;
	color: #e22024;
}

.profile_appointment_time_wrapper .react-datepicker__header {
	background-color: #e22024 !important;
}

.profile_appointment_time_wrapper .react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	color: #fff !important;
}

.react-datepicker__day--selected {
	background-color: #e22024 !important;
}

.react-datepicker__day .react-datepicker__day--keyboard-selected {
	background-color: #e22024 !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
	border-radius: 0.3rem;
	background-color: #e22024 !important;
	color: #fff;
}


.modal_footer_end
{
    justify-content: flex-end !important;
}
.btn .btn-primary .btn-md
{
	cursor: pointer;
}
.Preview_Icon_Main
{
	display: flex;
	justify-content: center;
	align-items: center;
}
.Preview_main_container_wrapper
{
	display: flex;
	align-items: center;
}
.multiple_image_submit_button
{
	padding-top: 10px;
}
.multiple_image_button:focus
{
	box-shadow: none !important;
}
.multiple_image_submit_wrapper
{
	min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* media */

@media(max-width:776px) {
	.profile_time_dropdown_wrapper {
		padding-bottom: 10px;
	}
}