
.submit_button_wrap
{
    padding: 14px 40px 14px 40px;
    background-color: transparent;
    background-image: linear-gradient( 
250deg
 , #4E95ED 19%, #03228F 100%);
    border-radius: 30px 30px 30px 30px;
    display: inline-block;
    color: #ffff;
}