.success_modal_title
{
    font-size: 16px !important;
    letter-spacing: 2px;

}
.success_modal_description
{
    text-align: center;
    color: #fff;
}
.success_modal_button
{
    color: #fff;
    background-color: #e22024 !important;
    border-color: #e22024 !important;
}
.success_modal_button:focus {
    color: #fff;
    background-color: #e22024;
    border-color: #141414;
    box-shadow: 0 0 0 0.2rem rgb(20 20 20);
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(20 20 20);
}