.form-control:hover
{
    cursor: pointer;
}
.common_top_padding
{
    padding-top: 40px;
}
.search_our_activity
{
    margin-top: 70px;
}



.search_our_activity
{
    position: relative;
    padding-bottom: 40px;
}

.our_activity_title
{
    color: white;
    letter-spacing: 20px;
    text-align: center;
    padding-top: 40px;
   padding-bottom:40px;
}
.our_activity_title span{
    color: #e22024 !important;
    font-weight: 600;
}





/* Search wrapper */

.our_activities_date_wrapper
{
    padding-bottom: 20px;
}

.search_section_wrapper
{
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
}
.search_section_inner
{
    align-items: center;
    justify-content: space-between;
    width: 100%;
}


.search_section_hightlight
{
    display: flex;
    width: 100%;
    border-radius: 20px;
    height: 30px;
}
.search_section_hightlight_first
{
    width: 50%;
    background-color: white;
    padding: 10px;
    text-align: center;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_section_hightlight_first h6{
    color: black !important;
    font-size: 13px;
}
.search_section_hightlight_second h6{
    font-size: 13px;
}
.search_section_hightlight_second
{
    width: 50%;
    background-color: #e22024;
    padding: 10px;
    text-align: center;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
 .search_section_dropdown
{
    border-radius: 50px;
    background-color: #e22024 !important;
    color:#ffff !important;
    border: #e22024;
}
.search_section_dropdown:hover
{
    cursor: pointer;
}
 .search_section_dropdown1
{
    border-radius: 50px;
    background-color: white !important;
}
.search_section_dropdown.form-control
{
    font-size: 10px !important;
    /* font-weight: 500; */
    position: relative;
    letter-spacing: 2px;
    background-color: #e22024 !important;
    color: white !important;

}
.search_section_dropdown1.form-control {
    font-size: 12px !important;
    /* font-weight: 500; */
    position: relative;
    letter-spacing: 2px;
    color: #e22024;
}
.search_section_text
{
border-radius: 50px;
}
.search_section_container
{
    position: relative;
}

.search_section_container
{
    background: white;
    border-radius: 50px;
}
.search_section_button {
    padding: 9px;
    color: #fff;
    background-color: #e22024;
    display: inline-block;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    position: absolute;
    top: -1px;
    right: -2px;
}
.search_section_button p{
    color: white;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
}

select.input-lg1 {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
  }
select + i.fa.cart_first_icons {
    position: absolute;
    top: 2px;
    right: 26px;
    color: #e22024;
    font-size: 25px;
  } 
select.input-lg {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
  }
  select + i.fa {
    position: absolute;
    top: 2px;
    right: 26px;
    color: #fff;
    font-size: 25px;
  }



/* End Search Wrapper */


/* search inner */
.search_section_inner h6
{
    color: white;
}

.date_wrapper_list
{
text-align: center;
}
.date_wrapper_list h6{
    font-size: 12px;
    padding-bottom: 2px;
    letter-spacing: 1px;
}

/* search inner */


/* Slider */

.steps
{
    border-color: transparent;
    border-style: none solid;
    border-width: 0 1px;
    margin: 0 0 35px!important;
    position: relative;
    width: 100%;
}
.tick {
    text-align: center;
}
.tick {
    color: #ccc;
    font-size: 11px;
    height: 11px;
    left: 0;
    line-height: 11px;
    /* margin-left: -24px; */
    position: absolute;
    width: 48px;
}
.tick1
{
    left: 12.5%;
}
.tick2
{
    left: 25%;
}
.tick3
{
    left: 37.5%;
}
.tick4
{
    left: 50%;
}
.tick5
{    left: 62.5%;

}
.tick6
{
    left: 75%;
}
.tick7
{
    left: 87.5%;
}.tick8
{
    left: 100%;
}


/* Interest */


.interst1
{
    left: 16.67%;
}
.interst2
{
    left: 33.34%;
}

.interst3
{
    left: 50%;
}

.interst4
{
    left: 66.67%;
}

.interst5
{
    left: 83.34%;
}

.interst6
{
   left: 100%;
}


.rangeslider-horizontal .rangeslider__handle:after
{
    display: none;
}
.rangeslider-horizontal .rangeslider__handle
{
    width: 13px !important;
    height: 35px !important;
}


/* End Slider */


/* search card */

.search_card_list
{
    padding-top: 80px;
    padding-bottom: 40px;
}
.search_card_image_container
{
    width: 50%;
}
.search_card_image_container img
{
    width: 100%;
    height: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.search_card_content_wrap
{
    width: 50%;
}
.search_card_main
{
    background-color:#242424;
    border-radius: 22px;
    display: flex;
    width: 100%;
    height: 350px;
    margin-bottom: 20px;
}
/* .search_card_wrapper img{
    width: 200px;
   
} */
.search_card_content_wrap
{
   padding: 40px 0px 20px 20px;
   color: white;
   letter-spacing: 1px;
}
.search_card_content
{
    display: flex;
    padding-bottom: 10px;
}
.search_card_content p{
   color: white;

}
.search_card_content .fa
{
font-size: 30px;
padding-right: 10px;
}
.search_card_time
{
    letter-spacing: 2px;

}
.search_card_time span{
    font-size: 12px;
}
.search_card_time
{
    padding-bottom: 10px;
}
.search_card_activities
{
    padding-bottom: 10px;
}
.search_card_activities p
{
    color: white;
}
.search_card_time p{
    background-color: white;
    display: inline-block;
    color: black;
    padding: 3px 10px;
    font-weight: bold;
    border-radius: 10px;
}
.search_card_price
{
    width: 90%;
display: flex;
    justify-content: space-between;
    align-items: center;
}
.search_card_price_amount
{
    margin-left: 10px;
}
.search_card_price span{
    font-size: 12px;
}
.search_card_price p
{
    color: white ;
    font-size: 16px;
    font-weight: 600;
}
.search_card_book
{
  
    font-weight: 600;
    background-color: #e22024;
    color: #fff;
    margin-right: 10px;
    padding: 4px 34px;
    border-radius: 10px;
    box-shadow: 1px 3px 9px #130e0e
}
.search_card_book p{
    color: #fff !important;
}
/* end search card */



















/* Media Query */

@media(max-width:776px)
{
    .our_activity_title
    {
        letter-spacing: 5px;
        padding-top: unset;
        padding-bottom: 20px;
    }
    .search_card_book 
    {
        padding: 5px 23px;
    }
    .search_card_time p {
        padding: 3px 3px;
        font-size: 11px;
    }
    .search_card_image_container {
        width: 40%;
    }
    .search_card_content_wrap {
        width: 60%;
    }
    .search_date_mobile
    {
        padding-right: 0px;
        padding-left: 30px;
    }
    .search_section_inner  .col-md-3, .search_section_inner .col-md-6
    {
        margin-bottom: 20px;
    }
    .search_our_activity::after
    {
        background-color: unset;
    }
    .first_section_mobile
    {
        order: 2;
    }
    .second_section_mobile
    {
        order: 1;
    }
    .third_section_mobile
    {
        order: 3;
    }
    .search_card_price
    {
        width: 100%;
    }
    .search_card_list
    {
        padding-top: 10px;
    }
    .search_card_price_amount
    {
        margin-left: unset;
    }




    /* search new correction */

    .our_activities_date_wrapper
    {
        padding-left: 25px;
        padding-right: 25px;

}


    /* search new correction */
   
}



/* End Media Query */