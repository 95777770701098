/* register_banner_section */
.register_banner_section
{
    background-color: #141414;
    background-image: url('../../../assets/dabblefit/register/banner/bg.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    height: 60vh;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-size: cover;
    display: flex;
    align-items: center;
}

.register_banner_section_overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #141414;
    width: 100%;
    height: 60vh;
    opacity: 0.9;
}
/* .register_section .bg-light, .register_section .navbar-collapse {
    background-color: #463b3b12 !important;
    color: white;
} */
.register_banner_content h1 {
    color: #fff;
    letter-spacing: 20px;
    text-align: center;
    font-size: 50px;
}
  .register_banner_content span{
    color: #e22024;
    font-weight: 800;
  }

  .top_scroll
  {
      background-color: #e22024 !important;
  }


/* register_banner_section */


/* register_offers_section */




.register_offers_section
{
    padding-top: 70px;
}
.register_offer_wrapper
{
    background-color: #1b1515;
    padding: 25px 20px;
    border-radius: 90px;
}


.register_offer_images
{
    height: 200px;
    width: 200px;
}
.register_offer_images img
{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.register_offer_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px;
}

.register_offer_container::after 
{
    position: absolute;
    content: '';
    background: linear-gradient(to bottom, #1b1515, #fff, #1b1515);
    right: 0;
    width: 2px;
    height: 75%;
    top: 40px;
}
.register_offer_container1::after 
{
    position: absolute;
    content: '';
    background: linear-gradient(to bottom, #1b1515, #fff, #1b1515);
    right: 0;
    width: 0px !important;
    height: 75%;
    top: 40px;
}
.register_offer_container h6 {
    color: #ffffff;
    text-align: center;
    letter-spacing: 1px;
    padding-bottom: 20px;
}



.register_offer_title
{
    position: relative;
    width: 50%;
}
.register_offer_container h6::after {
    position: absolute;
    content: '';
    background-color: #e22024;
    width: 170px;
    height: 1px;
    top: 50px;
    left: 3px;
}
.register_offer_description
{
    text-align: center;
}



/* register_offers_section */

/* get in touch */

.get_in_touch_section
{
    padding-top: 70px;
}
.get_int_title h6{
    color: #e22024;
    text-align: center;
    font-size: 24px;
    letter-spacing: 5px;
    padding-bottom: 10px;
}

.get_in_register_top
{
    text-align: center;
    padding-top: 20px;
}
.get_in_register p{
    background-color: #e22024;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 23px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 3px;
}

/* get in touch */






  /* Media Query */


@media(max-width:776px)
{
    .register_banner_section
    {
      height: 50vh;
    }
    .register_banner_section_overlay
    {
      height: 50vh;
    }
    .register_banner_content h1
    {
        font-size: 30px;
        letter-spacing: 2px;
    }
    /* .register_section .bg-light, .navbar-collapse {
        background-color: #141414 !important;
        color: white;
    } */
    .register_offer_container {
        padding: 8px;
      
    }
    .register_offer_container h6 {
        letter-spacing: 0px;
      
    }
    .register_offer_container h6::after {
        background-color: #e22024;
        width: 132px;
        left: 15px;
    }
    .register_offer_container::after
    {
        width: 0px;
    }
    .register_offers_section {
        padding-top: 50px;
    }
    .register_offer_bg
    {
        height: 17vh;
    }
    .get_in_touch_section
    {
        padding-top: 50px;

    }
}







  /* Media Query */