/* Navbar */
.navbar {
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 70px;
    align-items: center;
}
 .nav-set::after
{
        content: '';
        height: 70px;
        width: 100%;
        top: 0;
        left: 0;
        position: fixed;
        background-color: white;
        z-index: 99;
} 

.navbar-light .navbar-nav .nav-link {
    color: black !important;
    text-align: center;
}
.navbar .nav-link {
    margin: 0 10px;
}

.navbar-light .navbar-toggler-icon {
    background-image: url('../../../../assets/vimkes/homepage/icons/toogle.png');
}
.navbar-light .navbar-toggler {
    color: rgb(218 160 160 / 50%);
    border-color: white;
}

.navbar-brand img {
    width: 100px;
}

.navbar-light .navbar-nav .nav-link:hover {
    background-image:  linear-gradient(
        60deg
        , #03228F 62%, #0E73E4 100%);;
    transition: 0.4s;
    color: #ffff!important;
}

@media (max-width: 640px)
{
    .navbar {
        margin-top: 20px;
        height: unset;
    }
    .navbar-toggler {
        margin-right: 0px;
    }
    .navbar-brand img {
        width: 80px;
    }
}

@media (max-width: 450px)
{
    .navbar {
        margin-top: 15px;
        height: unset;
    }
    .navbar-toggler {
        margin-right: 30px;
    }
    .navbar-collapse {
        background-color: rgb(255, 255, 255);
        margin-left: -15px;
    }
    .navbar-brand img {
        width: 80px;
    }
}



/* Navbar */