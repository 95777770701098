.user_profile_navbar_brand
{
    display: flex;
    align-items: center;
}
.user_profile_navbar_brand p{
    padding-left: 10px;
}
.user_profile_navbar_brand p
{
    color: #e22024;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
}
.user_profile_logout
{
    color: #e22024 !important;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px; 
}

/* userprofile_count_section */
.user_profile_icons_section
{
    margin-top: 100px;

}
.user_profile_icons_container img
{
width: 30px;
}
.user_profile_icons_container
{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.user_profile_icons_container .nav-link
{
color: #e22024 !important;
font-size: 18px;
}
.dropdown-item:active {
    background-color: #ff0000 !important;
}
.user_profile_icons_container span{
    color:#e22024;
    font-weight: 500;
    font-size: 18px;
}
.userprofile_count_section
{
    margin-top: 70px;
    padding-bottom: 40px;
    position: relative;
}
.userprofile_count_content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.userprofile_count_content span{
  
    color: white;
    font-size: 200px;
}
.userprofile_count_number {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.userprofile_subtitle {
    color: #e22024;
    font-size: 40px;
    letter-spacing: 2px;
    font-weight: 700;
    padding-top: 20px;

}

/* userprofile_count_section */


/* user_profile_search_wrapper */



.user_profile_dropdown_wrapper
{
    position: relative;
}
select.user_profile_dropdown_inner {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
  }
select + i.user_profile_dropdown_inner_icons {
    position: absolute;
    top: 8px;
    right: 12px;
    color: black;
    font-size: 20px;
  }
.user_profile_dropdown_container
{
    font-size: 13px !important;
    position: relative;
    border-radius: 20px;
}
.user_profile_dropdown_wrapper .form-control:focus {
    color: black;
    background-color: #fff;
    border-color: #a0a0a0;
    outline: 0;
    box-shadow: unset;
}

.user_profile_dropdown_wrapper option {
    font-family: 'Montserrat', sans-serif;
    color: black;
    font-weight: 500;
    font-size: 14px;
    }
.user_profile_dropdown_wrapper option:hover {
       cursor: pointer;
        }





        
/* user_profile_search_wrapper */

.userprofile_search_wrapper {
    display: flex;
    justify-content: center;
}
.userprofile_search_inner
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.userprofile_search_container
{
    border-radius: 50px;
    width: 100%;
}

/* user_profile_search_wrapper */


/* user_current_booking_section */



.user_current_title h4
{
    text-align: center;
    text-transform: uppercase;
    color: white;
    letter-spacing: 10px;
    padding-bottom: 20px;
}
.user_current_title span{
    color: #e22024;
}

.user_current_cards_details {
    color: black;
    background-color: #242424;
    margin-top: 20px;
    /* box-shadow: 5px 3px 11px #291d1d; */
    width: 100%;
    padding: 20px 30px;
    border-radius: 25px;
}
.user_current_content
{
    justify-content: center;
}
.user_current_content p{
    
    padding: 10px 20px;
    background-color: #e22024;
    color: white;
    width: 100px;
    text-align: center;
    border-radius: 10px;
    margin-right: 10px;
}
/* .user_current_content p:first-child
{
    margin-bottom: 10px;

} */

.user_current_description h6 {
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #ffff;
}
.user_current_description span
{
    font-size: 14px;
    color: #e22024;
}
/* user_current_booking_section */

/* user_gallery_section */

.user_profile_common_top
{
    padding-top: 70px;
}

/* user_gallery_section */





/* media Query */

@media(max-width:776px)
{
    .user_profile_common_top{
        padding-top: 50px;
    }
    .userprofile_subtitle
    {
        font-size: 25px;
    }
    .user_current_title h4 {
      
        letter-spacing: 5px;
      
    }
    .userprofile_count_section {
       
        padding-bottom: 20px;
       
    }
    .user_profile_icons_container
    {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}


/* media Query */