
/* our_title_wrap */

.our_title_wrap
{
    margin-top: 70px;
    padding-bottom: 20px;
}
.our_title_container
{
    padding: 60px 0px;
}
.our_title_container h1{
    color: #ffff;
    text-align: center;
    letter-spacing: 20px;
    
}
.our_title_container span{
    color: #e22024;
    font-weight: 600;
}

/* our_title_wrap */



/* our_trainer_about */



.our_trainers_about
{
    background-color:  #242424;
    padding: 40px 0px 40px 0px;
    border-bottom-left-radius: 70px;
    border-bottom-right-radius: 70px;
}

.our_trainers_wrap
{
    position: relative;
    z-index: 2;
}
.our_trainers_wrap span{
    position: absolute;
    background-color: white;
    width: 800px;
    height: 180px;
    left: -400px;
    z-index: -1;
    border-top-right-radius: 122px 100px;
    border-bottom-right-radius: 122px 100px;
}
.our_trainers_wrap h1{
    margin-top: 30px;
    max-width: 40px;
    color: #e22024;
    display: inline;
}
.our_title_wrap span{
    font-weight: 600;
}
.our_trainers_location
{
    padding-top: 20px;

}
.our_trainers_inner
{
    display: flex;
    align-items: center;
    color: white;
}
.our_trainers_inner .fa
{
    padding-right: 10px;
    font-size: 20px;
}
.our_trainers_inner h6{
    margin-right: 15px;
    border-bottom: 1px solid white;
}
.our_verified_container
{
    padding-top: 20px;
    display: flex;
    align-items: center;
}

.our_verified_round
{
    background-color: #ffff;
    border-radius: 50%;
    padding: 11px;
    color: #ffff;
    margin-right: 15px;

}
.our_verfied_star p
{
    color: #ffff;
    margin-right: 15px;

}
.our_verfied_star_icons .fa{
    color: #ffff;
    padding-right: 5px;
    
}
.our_area_expertise
{
    padding-top: 20px;
    border-bottom: 1px solid white;

}
.our_area_expertise h6{
    color: #ffff;
    letter-spacing: 4px;
}
.our_area_expertise_inner {
    padding: 20px 0px;
}
.our_area_expertise_inner p {
    color: black;
    background-color: white;
    display: inline-block;
    border-radius: 25px;
    padding: 2px 14px;
    font-weight: 600;
    margin-right: 5px;
    margin-bottom: 10px;
    box-shadow: 5px 3px 11px #291d1d;
}
.our_area_about
{
    padding: 10px 0px;
}
.our_area_about h6{
    color: white;
    padding-bottom: 10px;
    letter-spacing: 2px;
}
.our_area_about p{
    color: white;
    font-size: 12px;
}
.our_about_icons_wrapper
{
    display: flex;
    max-width: 300px;
    justify-content: space-between;
    text-align: center;
    color: white;
    padding-top: 10px;
}
.our_about_icons_wrapper .fa{
    font-size: 40px;
}
.our_about_icons_wrapper p{
    color: white;
}
/* our_trainer_about */




/* our_certification_section */



.our_certification_section
{
    padding-top: 60px;
}

.our_certification_wrapper h6{
    color: white;
    text-transform: uppercase;
    letter-spacing: 3px;
}
.our_certification_about
{
    padding: 5px 0px 20px 0px;
    color: white;

}
.our_certification_about h6{
    padding-bottom: 10px;
}
.our_certification_about p{
color: white;
}
.dv-star-rating-star
{
    margin-top: 10px;
}
.dv-star-rating-star i{
    font-size: 20px;
    padding-right: 4px;
    font-weight: bold;
    color: #ffff;
}

.training_subscription_wrapper p{
    font-size: 14px !important;
    color: black !important;
    padding-bottom: 10px !important;
}

.our_session_wrapper
{
    display: flex;
    justify-content: center;
    align-items: center;
}
.our_session_wrapper_inner {
    width: 600px;
    background-color: white;
    border-radius: 50px;
    padding: 34px 8px;
    text-align: center;
    margin-top: 30px;
}
.our_session_wrapper_parent
{
    position: relative;
}
.our_session_wrapper_after::after 
{
    position: absolute;
    content: '';
    background: linear-gradient(to bottom, #fff, #e22024, #fff);
    right: 0px;
    width: 2px;
    height: 80px;
    top: 10px;
}

.our_session_wrapper_inner h6 {
    color: #e22024;
    font-weight: 700;
    font-size: 18px;
}
.our_session_wrapper_inner p {
    color: #e22024;
    font-size: 12px;
    /* padding: 9px 0px; */
    font-weight: 500;
}
.our_session_wrapper_inner h5{
    color: #000;
    font-size: 22px;
    font-weight: 600;
}



/* our_certification_section */


/* our_trainer_date */

.our_trainer_date
{ 
    margin-top: 30px;
    padding: 30px;
    background-color: #242424;
    border-radius: 50px;
}
.our_trainer_title h6{
    color: #ffff;
    text-transform: uppercase;
    font-size: 22px;
    letter-spacing: 5px;
    font-weight: 600;
}
.our_trainer_date .col-md-2
{
    display: flex;
    justify-content: center;
    align-items: center;
}
.our_trainer_maxwidth
{
    max-width: 1100px;
    margin: 0 auto;
}
.our_trainner_time
{
    padding-top: 30px;
}

.training_session_wrapper p{
    padding: 10px;
    margin-bottom: 30px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.our_trainner_time p{
    color: black;
    background-color: white;
    display: inline-block;
    border-radius: 7px;
    padding: 4px 13px;
    font-weight: 600;
    margin-right: 8px;
    margin-bottom: 10px;
    box-shadow: 5px 3px 11px #291d1d;

}
.our_tainer_timer
{
    display: flex;
    flex-wrap: wrap;
}
.our_tainer_timer_book
{
    padding-top: 30px;
}
.our_tainer_timer_book p{
    color: #fff;
    background-color: #e22024;
    display: inline-block;
    border-radius: 9px;
    padding: 7px 35px;
    font-weight: 600;
    margin-right: 15px;
    margin-bottom: 10px;
    box-shadow: 5px 3px 11px #291d1d;
    font-size: 18px;
    letter-spacing: 2px;
}
/* our_trainer_date */

.disclaimer_section
{
   padding-top: 40px;
}   


@media(max-width:776px)
{
    .our_title_wrap {
        margin-top: 40px;
        padding-bottom: 20px;
    }
    .our_trainers_wrap span {
        position: absolute;
        background-color: white;
        width: 660px;
        height: 129px;
        left: -394px;
        z-index: -1;
        border-top-right-radius: 122px 100px;
        border-bottom-right-radius: 122px 100px;
        top: -3px;
    }
    .our_facilites_slider_bottom
    {
    padding: 10px 10px 10px 10px !important;

    }
    .our_trainers_about
{
    padding: 40px 0px 30px 0px;
   
}
.our_about_icons_wrapper {
    max-width: unset;
    padding: 20px 10px 0px 10px;
}
    .our_trainers_wrap img
    {
        width: 200px;
    }
    
    .our_title_container h1
    {
        letter-spacing: 4px;
        font-size: 24px;
    }
    .our_title_container {
        padding: 10px 0px;
    }
    .our_trainer_wrapper_media
    {
        padding: 0px 40px;
    }
    .our_trainner_time p {

        margin-right: 6px;
        padding: 4px 6px;
     
    }
    .our_tainer_timer {
        display: flex;
        justify-content: unset;
        flex-wrap: wrap;
    }
    .our_trainer_title h6
    {
        padding-bottom: 20px;
    }
    .our_session_wrapper_inner h6
    {
        font-size: 15px;
    }
}