
.about-section-intro
{
    padding-top: 120px;
}
.about-intro-image
{
    padding: 40px;
    position: relative;
    z-index: 1;
}
.about-intro-shape
{
    position: absolute;
    bottom: 50px;
    z-index: 0;
    right: 10px;
}

.about-subtext
{
    background: #1273eb;
    display: inline-block;
    padding: 6px 25px;
    border-radius: 30px;
    font-weight: 400;
    color: #1273EB;
    background-color: #1273EB17;
    margin-bottom: 10px;
}
.about-subtitle
{
    color: #0a0a0a;
    font-family: 'Livvic', sans-serif;
    font-weight: 700;
    position: relative;
    margin: 0px 0px 20px 0px;
    font-size: 35px;
}
.about-subdescription
{
    font-size: 18px;
    margin: 0px 0px 10px 0px;
    color: #555;
    line-height: 2;

}
.about-intro-content-wrap
{
    padding: 40px 0px 0px 70px;
}


@media(max-width:776px)
{
    .about-intro-content-wrap {
        padding: 40px 0px 0px 0px;
    }
    .about-intro-image
    {
        padding: unset;
    }
    
    .about-subtitle {
    
        margin: 10px 0px 20px 0px;
        font-size: 24px;
    }
    .about-subtext {
        margin-bottom: 10px;
    }
    .get-started-mobile
    {
        text-align: center;
    }
}


/* about-working-process */


.about-working-process
{
    margin-top: 70px;
}
.working-flow-title-wrap
{
    padding-top: 15px;
    padding-bottom: 15px;
}
.working-flow-title{
    color: #0a0a0a;
    font-size: 22px;
    font-weight: 700;
}
.working-flow-paddingtop
{
    padding: 60px 0px; 
    

}


@media(max-width:776px)
{
    .working-flow-paddingtop {
        padding: 20px 0px;
    }
}


/* about-working-process */









/* our-price-section */


.our-price-section
{
    margin-top: 70px;
    background-color: #F5F6F9;
    padding-top: 70px;
    padding-bottom: 70px;
}
.our-price-card-padding
{
    padding-top: 70px;
}




/* our-price-section */