.provider_sign_up_wrapper {
	margin-top: 200px
}
.provider_signup_addressdetails .form-control
{
    margin-bottom: unset;
}

.provider_sign_up_wrapper .form-control:focus {
	color: #495057;
	background-color: #fff;
	border-color: #141414;
	outline: 0;
	box-shadow: 0 0 0 .2rem rgb(20 20 20)
}

.provider_signup_addressdetails {
	padding-top: 70px
}

.provider_signup_dropdown_wrapper {
	
    width: 100%;
    position: relative;
    padding-top: 5px;
}
.provider_signup_dropdown_wrapper_main
{
    display: flex;
    align-items: center;
}
select.provider_signup_container {
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none
}

select+i.provider_signup_container_icons {
    position: absolute;
    top: 12px;
    right: 26px;
    color: #000;
    font-size: 25px;
}

.provider_signup_container {
	font-size: 13px!important;
	position: relative;
    height: 40px;
}
.provider_signup_container .form-control
{
height: 40px;
}
.provider_signup_dropdown_wrapper .form-control:focus {
	color: #000;
	background-color: #fff;
	border-color: #a0a0a0;
	outline: 0;
	box-shadow: unset
}

.provider_signup_dropdown_wrapper option {
	font-family: Montserrat, sans-serif;
	color: #000;
	font-weight: 500;
	font-size: 14px
}

.provider_signup_dropdown_wrapper option:hover {
	cursor: pointer
}

/* proivder_signup_add_button */
.proivder_signup_add_button
{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
}
.proivder_signup_add_button p{
    background-color: #e22024;
    padding: 8px 35px;
    color: white;
    border-radius: 10px;
}


/* proivder_signup_add_button */

/* provicer_signup_card_section */

.provider_signup_card_section
{
    padding-top: 20px;
}
.provider_signup_card
{
    
    color: black;
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 14px;
    font-weight: 600;
    margin: 20px 0px;
    box-shadow: 5px 3px 11px #291d1d;
    width: 100%;
}
._2iA8p44d0WZ-WqRBGcAuEV{
    background-color: white;
}
/* .provider_signup_card_content
{
    display: flex;
    justify-content: space-between;
} */
.provider_signup_close_button
{
    display: flex;
    justify-content: flex-end;
}
.provider_signup_close_button .fa:hover
{
    cursor: pointer;
}
.provider_signup_card_content h6
{
    /* padding-bottom: 10px; */
    font-size: 16px;
    font-weight: 600;
}
.provider_signup_card_content span {
    font-size: 14px;
    color: #e22024;
}

.provider_signup_submit_wrapper{
    display: flex;
    justify-content: center;
}

.provider_signup_submit_wrapper .btn-danger {
    color: #fff;
    background-color: #e22024 !important;
    border-color: #e22024 !important;
}
.password__show
{
  position: absolute;
  top: 30px;
  right: 8px;
}
.password__show:hover{
  cursor: pointer;
}

.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(20 20 20);
}
/* provicer_signup_card_section */

@media(max-width:768px) {
	.provider_sign_up_wrapper {
		margin-top: 80px
	}
	.provider_sign_up_wrapper .form-group {
		margin-bottom: unset
	}
    .provider_signup_form_control .form-group
{
    margin-bottom: unset;
}
.provider_signup_dropdown_wrapper_main
{
    margin-top: 15px;
}
.proivder_signup_add_button
{
    margin-top: 15px;
}
}