
/* contact background */


.conactbackground
{
    background-image: url('../../../assets/vimkes/contact/banner/contactbg.jpg');
}


/* conact background */

/* contact-card-form */
.contact-form
{
    padding-top: 70px;
}
.contact-card-form
{
    padding: 58px 30px 40px 50px;
    background-color: transparent;
    background-image: linear-gradient(250deg, #4E95ED 0%, #03228F 100%);
     border-radius: 10px;
}
.subtext-title
{
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #F6F8FF;
    margin: 0 0 10px;
}
.subtext-heading
{
    line-height: 1.3em;
    color: #FFFFFF;
    margin: 0px 30px 10px 0px;
    font-family: 'Livvic', sans-serif;
    font-weight: 700;
    position: relative;
}

.contact-list-icons
{
    background-color: #FFFFFF;
    padding: 10px;
}
.contact-list-icons-wrap
{
    display: flex;
    margin-bottom: 30px;
    color: #FFFFFF;

}
.contact-list-padding
{
    padding:30px 0px;
}
.contact-list-icons
{
    background-color: #FFFFFF;
    padding: 10px;
    display: inline-block;
    border-radius: 50%;
    width: 48px;
    text-align: center;
    margin-right: 23px;
}
.contact-list-icons .fa
{
    font-size: 20px !important;
    color: #03228F !important;
}   
.contact-form-section
{
    padding: 0px 30px;
}
.subtext-color
{
    color: #1273EB;
   
}
.subtext-heading-color
{
    color: #0a0a0a;
    margin-top: 5px;
}
.contact-form-section .form-control
{
    padding: 20px 20px 20px 20px;
    border-radius: 5px 5px 5px 5px;
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    background-color: #F6F7F9;
}
.form-control:focus {
  
    box-shadow:none !important;
}

@media(max-width:776px)
{
    .contact-form-section
    {
     padding-top: 30px;
    }
}
/* contact-card-form */

/* contact-map-function */

.contact-map-function{
    padding-top: 70px;
    padding-bottom: 70px;
}
iframe
{
    border: 0px;
}

/* contact-map-function */