/* register_banner_section */
.our_blog_section_section
{
    background-color: #141414;
    background-image: url('../../../assets/dabblefit/blog/bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    height: 60vh;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-size: cover;
    display: flex;
    align-items: center;
}

/* .our_blog_section_section_overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #141414;
    width: 100%;
    height: 60vh;
    opacity: 0.9;
} */
.our_blog_section .bg-light, .our_blog_section .navbar-collapse {
    background-color: #463b3b12 !important;
    color: white;
}
.our_blog_section_section_content h1 {
    color: #fff;
    letter-spacing: 20px;
    text-align: center;
    font-size: 40px;
    font-weight: normal;
}
  .our_blog_section_section_content span{
    color: #e22024;
    font-weight: 600;
  }



  /* our_blog_card_section */


.our_blog_card_section
{
    padding-top: 70px;
}

.our_blog_card
{
    background-color:#242424;
    border-radius: 40px;
  
}
.our_blog_image_wrap
{
    width: 100%;
   
}
.our_blog_image_wrap img{
    width: 100%;
    border-top-left-radius: 38px;
    border-bottom-left-radius: 38px;
}
.our_blog_image_wrap1 img{
    width: 100%;
    border-top-right-radius: 38px;
    border-bottom-right-radius: 38px;
}
.our_blog_content_wrap
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.our_blog_title
{
    padding: 40px 20px;
}
.our_blog_author h6{
    color: #fff;
    padding-top: 10px;font-size: 14px;
}
.our_blog_title h4{
    color: #fff;
    letter-spacing: 3px;
    padding-bottom: 10px;
    font-weight: 600;
}
.our_blog_title p{
    color: #fff;
}

.our_blog_padding_bottom
{
    margin-bottom: 30px;
}






  /* our_blog_card_section */






  /* Media Query */
@media(max-width:776px)
{
    .our_blog_image_wrap img{
        width: 100%;
    
        border-bottom-left-radius: unset;
        border-top-right-radius: 38px;
        border-top-left-radius: 38px;
    }
    .our_blog_image_wrap1 img{
        width: 100%;
        border-top-right-radius: unset;
        border-top-right-radius: 38px;
        border-top-left-radius: 38px;
        border-bottom-right-radius: unset;
    }
    .our_blog_card
    {
        padding-bottom: 10px;
    }
}





  /* Media Query */