.account_dasboard_wrapper .account_dasboard_heading
{
    color: #fff;
}
.account_dashboard_card
{
    color: black;
    background-color: white;
    display: inline-block;
    border-radius: 10px;
    padding: 18px 14px;
    font-weight: 600;
    margin: 20px 0px;
    box-shadow: 5px 3px 11px #291d1d;
    width: 100%;
}
.account_dashboard_edit_wrapper
{
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding-bottom: 20px;   
}
.account_dashboard_edit_wrapper h5
{
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
}
.account_dashboard_edit_wrapper p
{
    background: #e22024;
    padding: 5px 20px;
    color: white;
    border-radius: 10px;
}
.account_dashboard_inner
{
    padding-top: 20px;
}
.account_dashboard_name_conent
{
    margin-bottom: 10px;
}

.account_dashboard_name_conent h5 {
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
}
.account_dashboard_name_conent span
{
    font-size: 14px;
    color: red;
}
.change_password_visibility
{
    visibility: hidden;
}

 .password_form::placeholder
{
    color:  #242424 !important;
    font-size: 12px;
}
.changepassword_button_wrapper
{
    padding: 10px;
    display: inline-block;
    background-color: #e22024;
    border-radius: 10px;
}
.changepassword_button_wrapper p{
    color: #fff;
}

.account_dashboard_Terms_Dashboard
{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.account_dashboard_button
{
    padding: 10px;
    display: inline-block;
    background-color: #e22024;
    border-radius: 10px;
}
.account_dashboard_button p{
    color: #fff;
}
.Logout_button
{
    padding: 10px;
    display: inline-block;
    background-color: #e22024;
    border-radius: 10px;  
}

.Logout_button p{
    color: #fff;
    font-weight: 500;
}

.btn-primary {
    color: #fff;
    background-color: #e22024;
    border-color: #e22024;
}
.btn-primary.focus, .btn-primary:focus {
    color: #fff;
    background-color: #e22024;
    border-color: #e22024;
    box-shadow: #e1536100;
}
.btn-primary:hover {
    color: #fff;
    background-color: #e22024;
    border-color: #e22024;
}
.profile_logo_image_container
{
    width: 300px;
}
