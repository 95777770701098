.blog-section
{
    margin-top: 110px;
}
.blog-section-title
{
    font-weight: 500;
    margin: 0 0 10px;
    display: block;
    color: #0b70e1;
    line-height: 28px;
    font-family: 'Livvic', sans-serif;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.blog-section-heading
{
    color: #0a0a0a;
    font-family: 'Livvic', sans-serif;
    font-weight: 700;
    position: relative;
    line-height: 40px;
    margin-bottom: 15px;
}
.blog-section-des
{
    font-size: 18px;
    font-weight: 400;
}

/* card section */

.blog-card-section 
{
    box-shadow: 0 6px 25px rgb(12 89 219 / 9%);
    margin-top: 15px;
    padding: 15px;
    transition: 0.3s;
    background-color: #ffffff;
    border-radius: 5px;


}
.blog-image-wrapper
{
    position: relative;
}
.blog-image-wrapper img{
    border-radius: 10px;
}
.blog-image-inner
{
    border-radius: 30px;
    color: #ffffff;
    background-image: linear-gradient(
        60deg
        , #03228F 62%, #0E73E4 100%);
    transition: 0.4s;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 20px;
    display: block;
    position: absolute;
    bottom: 13px;
    right: 12px;
}

.blog-calender
{
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    width: 75%;
}
.blog-calender .fa
{
    
    color: #03228F;
    margin-right: 10px;
}
.blog-content h3
{
    color: #0a0a0a;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 8px;
    line-height: 1.4;
}
.blog-content p
{
    color: #555;
    font-size: 16px;
    margin-bottom: 18px;
    line-height: 1.4;
}
.blog-learnButton 
{
    display: flex;
}
.blog-learnButton p
{
    color: #03228F;
    padding-right: 10px;
    font-weight: 600;
}
.blog-learnButton span
{
    color: #03228F;
    font-weight: 600;


}
/* card section */