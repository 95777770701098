.our-price-card
{
    padding: 27px 40px 40px 40px;
    background-color: #FFFFFF;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #EBEBEB;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 0%);
    margin-bottom: 30px;
    position: relative;

}
.active_price_plan
{
    background-color: transparent;
    background-image: linear-gradient(
270deg
, #4E95ED 0%, #03228F 100%);
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #EBEBEB;
    border-radius: 5px 5px 5px 5px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 0%);
    margin-bottom: 30px;
    position: relative;
}
.active_price_plan .our-price-amount-symbol
{
    color: #fff;
    font-size: 18px;
}
.active_price_plan .our-price-amount
{
    color: #fff;
    font-size: 35px;
    font-weight: bold;
}
.active_price_plan .our-price-list-wrap p
{
    color: #FFFFFF;
}
.active_price_plan .our-price-text
{
    color: #FFFFFF;
}
 .our-price-image-card
{
    text-align: center;
    position: relative;
}
.our-price-image-card img
{
    width: 110px;
}
.our-price-image-inner
{
    position: absolute;
    top: 30px;
    left: 0;

}
.our-price-image-inner p
{
   
    padding: 10px 23px 11px 23px;
    background-color: #1273EB;
    border-radius: 0px 30px 30px 0px;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-block;
    color: white;
}
.our-price-amount-wrap {
    text-align: center;
    padding: 20px 0px;
    border-bottom: 1px solid gainsboro;
}
.our-price-amount-symbol
{
    color: black;
    font-size: 18px;
}
.our-price-amount
{
    color: black;
    font-size: 35px;
    font-weight: bold;
}
.our-price-text{
    display: block;
}
.our-price-list
{
    padding: 30px 0px;
}

.our-price-list-wrap
{
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
}


.our-price-buynow
{
    position: absolute;
    bottom: -29px;
    left: 50%;
    transform: translate(-50%, -50%);
}


@media(max-width:776px)
{
    .our-price-section {
        margin-top: 70px;
        background-color: #F5F6F9;
        padding-top: 70px;
     
        padding-bottom: 20px;
    }
}
