/* card  */
.card-wrap
{
    box-shadow: 0 6px 25px rgb(12 89 219 / 9%);
    margin-top: 15px;
    padding: 15px;
    transition: 0.3s;
    background-color: #ffffff;
    border-radius: 5px;
}
.recent-card-title
{
    color: #0a0a0a;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600 !important;
    position: relative;
    z-index: 1;
    padding-bottom: 12px;
}
.recent-card-title::after
{
    content: "";
    position: absolute;
    border: 0;
    width: 50px;
    height: 2px;
    background: #0b70e1;
    z-index: 1;
    margin-left: 0;
    bottom: 0;
    left: 0;
}
.recent-card-main
{
    margin-top: 20px;
 
}
.recent-card-content
{
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid gainsboro;
    margin-top: 20px;

}
.recent-card-content img
{
    width: 100px;
}
.recent-card-wrap
{
    padding-left: 20px;
}
.recent-celander
{
    padding-top: 5px;
}
.recent-celander span{
    padding-left: 10px;
}
.recent-celander .fa
{
 color: #0B70E1;
}
.recent-celander
{
    display: block;
    font-size: 12px;
    color: #555;
}

/* card */