
/* footer-section */

.footer-section
{
    background-image: url('../../../../assets/vimkes/homepage/footer/footerbg.png');
    background-size: cover;
    background-color: #F5F6F9;
}

.footer-top-padding
{
    padding: 92px 0px 45px;
}

.footer-title
{
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin: 0 0 12px;
    color: #1c1b1b;
}
.footer-description
{
    color: #555;
    font-weight: normal;
    margin: 0 0 30px;

}
.footer-social-wrap ul
{
    display: flex;
}
.footer-social-wrap ul li{
    margin-right: 5px;
}

.footer-social-wrap ul li .fa
{
    text-align: center;
    font-size: 15px;
    transition: all .8s ease;
    background: rgba(255, 255, 255, 0.9);
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    color: #555;
    margin-right: 10px;
}
.footer-1
{
    padding-left: 70px;
}
.footer-1 ul li{
    padding-bottom: 15px;
}

.newsletter_email
{
    border: none;
    width: 91%;
    font-size: 13px;
    padding: 20px 60px 20px 20px;
    margin: 0;
    color: #0a0a0a;
    overflow: hidden;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0 0 25px #eee;
    
}
.newsletter-parent
{
    position: relative;
}
.newsletter_papper
{
      width: 35px;
    background-color: white;
    text-align: center;
    border-radius: 50px;
    line-height: 35px;
    font-size: 15px;
    color: white;
    background-image: linear-gradient( 
90deg
 , #03228f 0%, #0e73e4 100%);
    position: absolute;
    top: 26px;
    right: 30px;

} 
.footer-inner
{
    padding: 20px;
}


/* media */


@media(max-width:776px)
{
    .footer-1
    {
        padding-left:  15px;
    }
.footer-mobile-padding
{
    padding-top: 20px;

}
}

/* media */