/* font-family: 'Livvic', sans-serif; */





body,p{
 
    color: #454545;
    font-family: Livvic !important;
    font-size: 16px ;

  
}

body, ul, ol, li, h1, h2, h3, h4, h5, h6, figure, p {
    padding: 0;
    margin: 0;
    list-style: none;
}
body {
    background: #FFFFFF;
     font-family: 'Livvic', sans-serif;
    scroll-behavior: smooth;
    /* overflow-x: hidden; */
}
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: 'Livvic', sans-serif;
    scroll-behavior: smooth;
   

    
 
}
a,h1,h2,h3,h4,h5,h6,p
{
    cursor: pointer;
    text-decoration: none;
  font-family: 'Livvic', sans-serif;
}
a:hover
{
    text-decoration: none;
}
/* common style */

.common_vertical_division
{
    display: flex;
    align-items: center;
}

/* common style */

/* home-section */

.home-section
{
    background-color: transparent;
    background-image: linear-gradient(60deg, #03228F 62%, #0E73E4 100%);
    height: 75vh;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-position: center right 0;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 110px;
}
.home-section .home-heading
{
    font-size: 45px;
    font-weight: 700;
    line-height: 1.3em;
    color: #FFFFFF;
    margin: 0px 0px 15px 0px;
}
.home-section .home_description
{
    color: #FFFFFF;
    margin: 0px 0px 4px 0px;
}
.home-section {
    min-height: 600px;
}
@media (max-width: 776px)
{
    .home-section .home-heading {
        font-size: 32px;
    }
    
}

@media (min-width: 1280px)
{
    .home-section {
        min-height: 600px;
    }
}





/* home-section */

/* brand-section */

.brand-section

{
    box-shadow: 0px 8px 50px 0px rgb(0 0 0 / 5%);
 
    margin-bottom: 0px;
    padding: 55px 0px 40px 0px;

}
.brand-image-wrapper img
{
    width: 200px;
}



@media(max-width:776px)
{
    .brand-section {
        padding: 30px;
    }
   
    .brand-image-wrapper img
{
    width: 100%;
}
}


/* brand-section */




/* about-section */

.about-section
{
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 100px 0px 0px 0px;
}





/* about-section */

/* about-title-wrapper */


.about-title-wrapper
{
    padding-top: 80px;
}
.about-section-content-wrapper
{
    padding-top: 80px;
}


.service-card-wrapper
{
    margin: 0px 0px 0px 0px;
    padding: 50px 25px 50px 25px;
    background-color: #FFFFFF;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 50px 11px rgb(95 138 236 / 5%);
    transition: all .2s ease-in-out;
}
.service-card-image img{
    width: 70px;
}
.service-title h3
{
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin: 0 0 12px;
    color: #1c1b1b;
}
.service-text
{
    padding: 20px 0px 0px 10px;
}
.service-txt
{
    color: #444444;
    font-weight: normal;

}
.service-card-first:hover 
{
    background-color: #fd660a;
    cursor: pointer;
}
.service-card-wrapper:hover .service-hover-image
{
    display: block;
    transform: scale(1.1);
   
}
.service-card-wrapper:hover .service-original-image
{
    display: none;
}
.service-hover-image
{
    display: none;
}
.service-card-second:hover 
{
    background-color: #f4bd19;
    cursor: pointer;
}
.service-card-third:hover 
{
    background-color: #14d2f5;
    cursor: pointer;
}
.service-card-four:hover 
{
    background-color: #2c44e4;
    cursor: pointer;
}
.service-card-wrapper:hover .service-title h3
{
    color: white;
}
.service-card-wrapper:hover .service-txt
{
    color: white;
}

.service-card-wrapper-margintop
{
    margin-top: 40px;
}

.count-number-wrapper .count-number, .count-number-wrapper .count-prefix
{
    color: #DD4C23;
}
.project-number-wrapper .count-number, .project-number-wrapper .count-prefix
{
    color: #03228F;
}
.count-number
{
    font-size: 45px;
    font-weight: 700;
    padding: 0px 0px 0px 0px;
}
.count-prefix
{
    font-size: 30px;
    font-weight: 700;   
}
.count-title
{
    color: #000000;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    transition: all 0.3s ease 0s;
}
@media(max-width:776px)
{
    .title {
       
        font-size: 25px;
        line-height: 36px;
        width: unset;
    }
    .description
    {
        width: unset;
    }
    .about-section {
        padding: 0px 0px 0px 0px;
    }
    .about-section-content-wrapper {
        padding-top: 40px;
    }
   
}



/* about-title-wrapper */


/* skillset-section wrapper */


.skillset-section
{
    padding-top: 70px;
}

.skillset-image-wrapper
{
    margin: 0px 68px 0px -68px;
}
.progressbar-main-wrapper
{
    padding-top: 20px;
}

.progressbar-wrapper
{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}
.progress
{
    height: 7px;
}



@media(max-width:776px)
{
    .skillset-section {
        padding-top: 30px;
    }
    .skillset-image-wrapper {
        margin: unset;
        margin-bottom: 20px;
    }
    .why-choose-image-wrapper {
        margin: unset;
        margin-bottom: 20px;
    }
}








/* skillset-section wrapper */




/* services-section wrapper*/


.services-section
{
    background-color: #F6F7F9;
    margin-top: 100px;
}
.service-getstarted-button
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.service-margin-section
{
    margin-top: 90px;
    padding-bottom: 90px;
}
.service-section-cardwrap
{
    margin-top: 50px;
   
}
.service-section-card
{
    margin: 0px 0px 0px 0px;
    padding: 50px 25px 50px 25px;
    background-color: #FFFFFF;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 50px 11px rgb(95 138 236 / 5%);
}
.service-card-marginbottom{
    margin-bottom: 20px;
}

.service-card-image
{
    position: relative;
}
.service-card-serial{
    color: #02010108;
    font-size: 70px;
    font-weight: 600;
    position: absolute;
    top: 30px;
    right: 30px;
}

.service-card-wrapper:hover .service-card-serial{
color: #FFFFFF26;
}


@media(max-width:776px)
{
    .service-margin-section
    {
        padding-bottom: 20px;
        margin-top: unset;

    }
    .services-section
    {
        padding-top: 40px;
    }
    .service-getstarted-button
    {
        display: flex;
        justify-content: center;
        
    }
   


}




/* services-section wrapper */

/* why-choose-section */


.why-choose-section
{
 padding-top: 70px;
}

.why-choose-image-wrapper
{
    margin: 0px 68px 0px -68px;
}


.list-details-wrapper
{
    padding-top: 30px;
}


@media(max-width:776px)
{

    .why-choose-image-wrapper {
        margin: unset;
        margin-bottom: 20px;
    }
}
/* why-choose-section */

/* schedule-section */

.schedule-section
{
    margin-top: 70px;
    background-color: #f6f7f9;
    padding-bottom: 40px;
}
.let-talk-padding
{
    margin-top: 110px;
}
.schedule-card-form
{
    background-color: #FFFFFF;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 0px 50px 11px rgb(95 138 236 / 5%);
    transition: all .2s ease-in-out;
    text-align: center;
    padding: 20px;

}

.schedule-title{
    margin: 0px 0px 5px 0px;
    color: #0a0a0a;
    font-family: 'Livvic', sans-serif;
    font-weight: 700;
    position: relative;
    font-size: 25px;
}
.schedule-description
{
    text-align: center;
}

.btn-primary{
    background-image: linear-gradient(220deg, #F27C1E 0%, #DD4C23 79%);
    border: 0;
}
.btn-primary:focus {
    box-shadow: none;
}

@media(max-width:776px)
{
    .let-talk-padding
    {
        margin-top: 50px;
    }
}



/* schedule-section */


/* product-section */

.product-section

{
    box-shadow: 0px 8px 50px 0px rgb(0 0 0 / 5%);
    margin-bottom: 0px;
    padding: 55px 0px 40px 0px;
    margin-top: 70px;
    background-image: linear-gradient(150deg, #03228F 46%, #0E73E4 100%);

}
.product-image-wrapper img
{
    width: 200px;
}



@media(max-width:776px)
{
    .product-section {
        padding: 30px;
    }
   
    .product-image-wrapper img
{
    width: 100%;
}
}


/* brand-section */



@media (min-width: 1440px)

{
    .container {
        max-width: calc(100vw - 300px);
  
    }
}


@media (min-width: 1600px)
{
    .container {
        max-width: calc(100vw - 600px);
       

    }
}

@media only screen and (min-width: 1300px)
{
    .container {
        max-width: 1280px;
    }
}











/* every section margintop */


/* margin top: 50px */
/* margin bottom: 20px */




/* every section margintop */