.list-details-mainwrap
{
    display: flex;
    padding-bottom: 20px;
}
.list-details-mainwrap img{
    width: 70px;
    margin-right: 10px;
    padding: 10px;
}
.list-details-description h3
{
    padding-bottom: 5px;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: #101010;
}
