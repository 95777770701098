.home_dasboard_wrapper
{
    padding: 40px 0px 0px 0px;
}
.home_dasboard_main_view
{
    border-top: 1px solid #e22024;
    border-left: 1px solid #e22024;
    border-top-left-radius: 40px;

}

.home_dasboard_main_wrapper
{
    position: relative;
}
/* .home_dasboard_main_view::after
{
    position: absolute;
    content: '';
    background-color: #e22024;
    height: 50px;
    width: 200px;
    top: -50px;
    right: 0px;
} */
.home_dasboard_main_view::before {
    position: absolute;
    content: '';
    background-color: #242424;
    height: 93px;
    width: 85px;
    right: 0px;
    bottom: -89px;
    z-index: -1;
}
.home_dasboard_section .footer_section
{
    margin-top: 0px;
}
.home_dasboard_main_view
{
    padding: 50px;
    background-color: #242424;
}
.home_dasboard_sidenav_image
{
    text-align: center;
}
.home_dashbord_sidenav_unorderlist
{
    padding: 30px 0px;
    display: flex;
    justify-content: center;
}
.home_dashbord_sidenav_unorderlist ul li {
    color: #fff;
    letter-spacing: 1px;
    padding-bottom: 10px;
}
.home_dashbord_sidenav_unorderlist ul li:hover{
    cursor: pointer;
}
.highlight_color1
{
    color: #e22024 !important ;
}
.home_dashbord_sidenav_unorderlist .highlight_color
{
    color: #e22024 ;
}
.home_dashbord_sidenav_unorderlist ul li{
    position: relative;
}
.highlight_color::after
{
    content: '';
    background-color: red;
    width: 50px;
    height: 1px;
    position: absolute;
    bottom: 5px;
    left: 0px;
    
}




/* side nav */
.home_dashboard_dropdown_inner
{
    display: flex;
    justify-content: flex-end;
    position: relative;
}
.home_dashboard_dropdown_inner .home_dashbord_dropdown_wrapper
{
    width: 400px;

}



.highlight_color::before
{
    content: '';
    background-color: red;
    width: 50px;
    height: 1px;
    position: absolute;
    bottom: 5px;
    left: 0px

}

.home_dasboard_main_view1::before {
    position: absolute;
    content: '';
    background-color: #242424;
    height: 93px;
    width: 100%;
    left: 0px;
    bottom: -89px;
    z-index: -1;
}
.provider_dashboard_dropdown_wrapper {
    display: flex;
    justify-content: flex-end;
    /* padding-top: 40px; */
}



/* media Query */

@media(max-width:776px)
{
    .home_dasboard_main_view{
        padding: 30px 20px;
        margin-top: 0px;
    }
    .home_dashboard_dropdown_inner {
        display: flex;
        justify-content: flex-end;
        position: relative;
        margin-top: 100px;
        padding: 0px 10px;
    }
    
}

/* End Meida Query */


