.sub-text

{
    position: relative;
    padding-left: 95px;
    display: inline-block;
    font-weight: 400;
    color: #03228F;
    margin-bottom: 10px;
}
.sub-text::before {
    content: '';
    position: absolute;
    left: 0;
    top: 12px;
    height: 4px;
    width: 76px;
    background: #FD6509;
}
.sub-text::after {
    content: '';
    position: absolute;
    left: 15px;
    top: 11px;
    height: 6px;
    width: 12px;
    background: #ffffff;
}
.title
{
    line-height: 1.3em;
    margin: 0px 0px 15px 0px;
    color: #0a0a0a;
    font-family: 'Livvic', sans-serif;
    font-weight: 700;
    position: relative;
    font-size: 40px;
    line-height: 50px;
}
.description
{
    margin: 15px 0px 0px 0px;
    /* width: 450px; */
    margin-bottom: 10px;
}
.description p{
    color: #444444;
    font-size: 18px;
}