/* our_facilites_header */

.our_facilites_wrap
{
    margin-top: 70px;
    padding-bottom: 20px;
}
.our_facilites_title
{
    padding: 60px 0px;
}
.our_facilites_title h1{
    color: #ffff;
    text-align: center;
    letter-spacing: 20px;
    
}
.our_facilites_title span{
    color: #e22024;
    font-weight: 600;
}
.our_facilites_image
{
    position: relative;
    z-index: 2;
}
.our_facilites_image span{
    position: absolute;
    background-color: white;
    width: 800px;
    height: 180px;
    left: -400px;
    z-index: -1;
    border-top-right-radius: 122px 100px;
    border-bottom-right-radius: 122px 100px;
}

.our_facilites_location
{
    padding: 20px 0px;
}
.our_facilites_location
{
    display: flex;
    align-items: center;
}
.our_facilites_location h3{
    color: #fff;
    letter-spacing: 3px;
}
.our_facilites_location h3 span{
    color: #fff;
    font-weight: 600;
    
}
.our_facilites_location .fa{
    margin-right: 10px;
    font-size: 50px;
    color: #ffff;
}
.our_facilites_location
{
    position: relative;
}
.our_facilites_location::after
{
    position: absolute;
    content: '';
    background-color: rgb(216 155 155);
    width: 100%;
    height: 1px;
    bottom: 5px;
}
/* our_facilites_header */

/* our_facilites_about */

.our_facilites_about
{
    background-color: #242424;
    padding: 40px 0px;
}

.our_facilites_description
{
    padding: 30px 0px;
    color: white;
}
.our_facilites_description p{
    color:#fff;
}
.our_facilites_icons
{
    display: flex;
   justify-content: space-between;
}
.our_facliites_inner_icons
{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.our_facilities_slider
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
}
.our_facliites_inner_icons img{
    width: 50px;
    padding-bottom: 10px;
}
.our_facliites_inner_icons h6{
    letter-spacing: 6px;
    color: white;
    align-items: center;
    font-size: 14px;
    text-transform: uppercase;
}

.our_facilites_image_wrapper img
{
    border-radius: 50px;
  
}
.workout_banner
{
    height: 100%;
    width: 100%;
}



/* our_facilites_about */



/* our_date_section */

.our_date_section
{
   padding-top: 70px;
   padding-bottom: 10px;
}
.our_date_title h6{
    color: #ffff;
    text-transform: uppercase;
    font-size: 22px;
    letter-spacing: 5px;
    font-weight: 600;
}
.our_date_section .col-md-2
{
    display: flex;
    justify-content: center;
    align-items: center;
}
.date_wraper_inner_list h6{
    color: #ffff;
}

/* our_date_section */


/* our_facilites_card_wrapper */


.our_facilities_card_wrap
{
padding-top: 40px;
}
.our_facilities_card_background
{
    background-color: #242424;
    border-radius: 20px;
    margin-bottom: 20px;
}
.our_facilities_card_background .col-md-4
{
    display: flex;
    justify-content: center;
    align-items: center;
}
.our_image_container
{
  /* width: 75%;
  height: 100px; */
  margin: 10px;
  height: 130px;

}
.our_image_container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.our_facilities_content
{
    padding: 20px 10px 10px 0px;
}
.our_activity_container
{
    padding: 0px 0px 15px 0px;
    color: #fff;
}
.our_activity_conent p
{
font-size: 18px;
color: #ffff;
letter-spacing: 3px;
text-transform: uppercase;
}
.our_activity_container
{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.our_activity_conent span{
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 3px;
}
.our_difficulty_container
{
    text-align: center;
}
.our_difficulty_container h6
{
   
    text-transform: uppercase;
    font-size: 8px;
    margin-bottom: 8px;
    letter-spacing: 4px;
}
.our_difficulty_container span{
    padding: 7px 30px;
    border: 1px solid #ffff;
    border-radius: 14px;
    text-align: center;
   font-weight: 700;
   letter-spacing: 4px;
   text-transform: uppercase;
}



.our_clock_container
{
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
}
.our_clock_wrapper
{
    color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.our_clock_wrapper span{
    font-weight: 600;
}
.our_clock_wrapper .fa{
    font-size: 20px;
    padding-right: 10px;
}
.our_clock_content h6{
    letter-spacing: 4px;
    color: white;
    font-size: 20px;
}
.our_clock_content span{
    font-weight: 600;
}

.our_clock_timing {
    display: flex;
    color: #000;
    flex-wrap: wrap;
    /* padding: 5px 20px;
    align-items: center;
    justify-content: center; */
}
.our_clock_timing span{
    padding: 5px 10px;
    background-color: white;
    font-weight: 600;
    border-radius: 10px;
}
.our_clock_timing div{
    margin-bottom: 10px;
    margin-right: 5px;
}
.our_benifits_content
{
    margin: 0px 10px 10px 20px;
    color: #fff ;
}
.our_benifits_content ul {
    padding: 0px 10px;
}
.our_benifits_content ul li{
    padding: 0px ;
    font-size: 12px;
    list-style-type: disc;
}
.our_price_content
{  margin: 0px 10px 10px 20px;
    color: #fff ;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.our_price_wrapper p
{
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 3px;
}
.our_price_wrapper span{
    font-size: 22px;
    font-weight: 600;
}
.our_book_button span
{
    background-color:#e22024;
    color: #fff;
    padding: 8px 40px;
    font-weight: 700;
    border-radius: 10px;
    letter-spacing: 3px;
    font-size: 18px;
    box-shadow: 5px 3px 11px #291d1d;
    
}
.our_book_button:hover
{
    cursor: pointer;
}
.our_view_section
{
    text-align: center;
    color: #fff;
    margin-top: 4px;
}
.our_view_section p
{
    text-align: center;
    color: white;
    border-bottom: 1px solid white;
    display: inline-block;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 2px;
}
/* our_facilites_card_wrapper */

/*  disclaimer wrapper */

.disclaimer_title h6{
    color: #e22024;
    text-align: center;
    font-weight: 800;
    letter-spacing: 4px;
    padding-bottom: 10px;
}

.disclaimer_section
{
   padding-top: 40px;
}
.disclaimer_card
{
    padding: 20px;
    border-radius: 20px;
}
.disclaimer_description
{
    padding: 10px 0px;
}
.disclaimer_description ul li{
    color: #ffff;
    font-size: 10px;
    list-style-type: disc !important;
    text-align: justify;
    padding-bottom: 5px;
    font-weight: 600;
   
   
}


/*   disclaimer wrapper */



/* Media Query */
@media(max-width:776px)
{
    .our_facilites_title h1{
        letter-spacing: 4px;
        font-size: 24px;
    }
    .our_facilites_title
    {
    padding: 0px 0px;
     }
     .our_facilites_image_wrapper
     {
         margin-top: 20px;
     }
     .our_facliites_inner_icons h6 {
        letter-spacing: 1px;
        font-size: 12px;
    }
    .our_facilites_image_conainer
    {
        width: 40%;
    }
    .our_time_card {
        padding: 4px 5px;
      
    }
    .our_facilites_card_content {
        /* width: 100%;
        padding: 40px 10px;
        color: white; */
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 0px 12px;
        width: 100%;
    }
    .our_facilites_card_section
    {
        padding-top: 40px;
    }
    .our_facilites_activity h6
    {
        font-size: 13px;
    }
    .disclaimer_card
    {
        margin: 20px;
    }
    .our_facilities_slider
    {
        padding: 10px 10px 50px 10px;
    }
    .our_facilities_slider  .slick-prev , .our_facilities_slider  .slick-next
    {
        display: none !important;
    }
    .our_date_section {
        padding: 30px 30px 0px 30px;
    }
    .our_facilites_image span {
        position: absolute;
        background-color: white;
        width: 660px;
        height: 129px;
        left: -394px;
        z-index: -1;
        border-top-right-radius: 122px 100px;
        border-bottom-right-radius: 122px 100px;
        top: -3px;
    }
    .our_facilites_image img
    {
        width: 200px;
    }
    .our_date_title h6
    {
        padding-bottom: 20px;
    }
    
    .our_facilites_description
    {
        padding: 5px 0px;
    }

    /*card */

    .our_image_container {
        width: 100%;
        height: unset;
        margin: 10px;
    }
    .our_image_container  img{
       border-radius: unset;
       height: 80px;
       width: 80px;
    }
    
    
    .our_facilities_content
    {
        padding: 20px 10px 10px 10px;
    }
    .our_facilities_card_background .col-md-4
    {
        justify-content: unset;
    }
    .our_clock_timing {
        display: flex;
        /* justify-content: space-between; */
        color: #000;
        flex-wrap: wrap;
        padding: 5px 20px;
        align-items: center;
    }
    .our_clock_timing {
        display: flex;
        color: #000;
        flex-wrap: wrap;
    }
    .our_clock_timing div
    {
        margin-bottom: 10px;
    }
    .our_activity_conent p
    {
        font-size: 12px;
    }
    .our_clock_content h6 {
        letter-spacing: 4px;
        color: white;
        font-size: 15px;
    }
    .our_clock_timing span {
        padding: 5px 5px;
        background-color: white;
        font-weight: 600;
        border-radius: 10px;
    }

    /* end card */
    
}