.schedule_dashboard_title h6
{
    color: #fff;
}

.add_new_session_container
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.add_new_session_container p{
    color: #fff;
    background-color: #e22024;
    padding: 10px;
    text-align: center;
}

.schedule_time_table_padding_top
{
    padding-top: 30px;
}


.schedule_time_table_card_wrap
{
    color: black;
    background-color: #fff;
    margin-top: 20px;
    box-shadow: 5px 3px 11px #291d1d;
    width: 100%;
}

/* table correction */


.table thead th
{
    border-bottom: unset;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: unset;
}
.table td, .table th {
    border-top: 1px solid #f2f6f9;
}
.table-bordered td, .table-bordered th {
    border: unset;
    border-top: 1px solid #f2f6f9 !important;
}
.table:hover
{
    cursor: pointer;
}
.table{
    font-family: 'Montserrat', sans-serif;
}
.schedule_text_font_weight
{
    font-weight: 600;
    color: black;
}

/* table correction */

/* schedule_time_view_wrapper */


.schedule_time_table_card_wrap
{
    overflow-x:auto;
}
.schedule_time_view_wrapper {
    color: black;
    background-color: #fff;
    margin-top: 20px;
    box-shadow: 5px 3px 11px #291d1d;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* schedule_time_view_wrapper */

/* schedule_session_type_wrap */

.schedule_session_type_wrap
{
    color: black;
    background-color: #fff;
    margin-top: 20px;
    box-shadow: 5px 3px 11px #291d1d;
    width: 100%;
    padding: 20px;
  
}

.schedule_session_type_wrap_padding
{
    padding: 18px 14px;
}
.schedule_checkbox_wrap
{
  
    padding-top: 20px;
}
.schedule_checkbox_main
{
    display: flex;
    justify-content: space-between;
}

.schedule_checkbox_main:hover
{
    cursor: pointer;
}


.profile_date_wrapper
{
    padding: 20px 0px;
}



.no_end_date_checkbox
{
      display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}
/* .schedule_repeat_on_section_wrap
{
    padding-top: 10px;
} */
.schedule_repeat_on_section
{
    display: flex;
}
.schedule_repeat_on_section p
{
    border: 1px solid #e6e6e6;
    padding: 10px;
}
.schedule_repeat_hightlight
{
    background-color: red;
    color: white;
}
.schedule_start_end_time_wrapper
{
    padding-top: 20px;
}
.schedule_common_padding_top
{
    padding-top: 20px;
}
.schedule_save_button
{
    display: flex;
    justify-content: flex-end;
}
.schedule_save_button p{
    color: #fff;
    background-color: #e22024;
    padding: 10px;
    text-align: center;
}
.Session_title_padding
{
    margin-bottom: .5rem;
    color: black;
}

.Session_Add_button_center
{
    display: flex;
    justify-content: center;
    align-items: center;
}
.schedule_padding_bottom_wrapper
{
    padding-bottom: 15px;
}

.input_time_picker_style
{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
/* .session_end_date_wrapper
{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0px;
} */
/* schedule_session_type_wrap */