/* background */

.blogbackground
{
    background-image: url('../../../assets/vimkes/bloglist/bg.jpg');
}

/* background */

/* blogs-list-section */
.blogs-list-section
{
    padding-top: 70px;
    padding-bottom: 70px;
}

.sticky-top {
	top: 70px;
}

/* blogs-list-section */