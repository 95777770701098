
.modal-content
{
    border-radius: 30px;
}

.modal-header {
    justify-content: center;
    background-color: #e22024;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
    color: white;
    text-transform: uppercase;
    padding: 2rem 1rem;
    border-bottom:transparent;
}
.modal-content
{
    border-radius: 30px !important;
}
.modal-title
{
    letter-spacing: 7px;
    font-weight: 700;
}
.modal-body
{
    background-color: #141414;
    padding: 2rem 1rem;
}
.modal-footer
{
    justify-content: center;
}
.modal-footer {
    justify-content: center;
    background-color: #141414;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
}
.modal_create_para
{
    text-align: center;
    color: #e22024;
    letter-spacing: 3px;
    font-size: 18px;
    font-weight: 600;
}


.sign_form_wrapper
{
    position: relative;
}
.sign_form_wrapper .form-control{
    height: 50px;
    padding-left: 40px;
}

.sign_form_icons {
    position: absolute;
    top: 37px;
    left: 15px;
}

.sign_form_icons .fa {
    font-size: 19px;
    color: red;
}
.sign_form_check_wrapper
{
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sign_form_check_wrapper .form-check-label
{
    font-size: 12px;
}