

/* Main Nav */


.home_dasbord_period_dropdown h6{
    color: #fff;
   text-transform: capitalize;
    padding-bottom: 20px;
}
.home_dashbord_dropdown_wrapper
{

    position: relative;
    padding-bottom: 20px;
}
.home_card_subtitle
{
    color: #ffff;
}
.home_card_highlight
{
    color:#e22024
}


.dasboard_section_dropdown
{
    border-radius: 50px;
    background-color: white !important;
    color:#e22024 !important;
    font-size: 12px;
}
.dasboard_section_dropdown:hover
{
    cursor: pointer;
}

.dasboard_section_dropdown.form-control:focus
{
    box-shadow: none;
}

select + .period_icons {
    position: absolute;
    top: 4px !important;
    right: 26px;
    color: #e22024 !important;
    font-size: 25px;
}



.Revenue_card_dasborad
{
    color: black;
    background-color: white;
    display: inline-block;
    border-radius: 40px;
    padding: 2px 14px;
    font-weight: 600;
   margin: 20px 10px;
    box-shadow: 5px 3px 11px #291d1d;
    width: 100%;
}
.Revenue_card_conent
{
    padding: 10px 30px;
}
.Revenue_card_conent h6 {
    color: #e22024;
    font-weight: 600;
    font-size: 14px;
}
/* Main Nav */
