.package_dashboard_main_wrapper h6{
    color: #fff;
}
.package_dashboard_wrapper
{
    color: black;
    background-color: #fff;
    border-radius: 10px;
    padding: 18px 14px;
    font-weight: 600;
    margin: 20px 0px;
    box-shadow: 5px 3px 11px #291d1d;
    width: 100%;
}

.package_button_wrapper
{
    display: flex;
    justify-content: flex-end;
}
.package_button_container p
{
    background-color: #e22024;
    display: inline-block;
    color: white;
    padding: 5px 15px;
    width: 80px;
    margin: 0px 10px;
    text-align: center;
}
.package_dashboard_content
{
    padding-top: 20px;
    color: #fff;
}
.package_dashboard_content p{
    color: white;
    padding-bottom: 5px;
}

.package_dashboard_content ul{
    display: inline-block;
    padding-left: 17px;
}

.package_dashboard_content ul li{
    list-style-type: disc;
}