.provider_signin_buttton_wrapper
{
    padding-top: 20px;
}

.provider_sign_in_wrapper
{
    margin-top: 200px;
    padding-bottom: 150px;
}
.btn-danger {
    color: #fff;
    background-color: #e22024 !important;
    border-color: #e22024 !important;
}

.contact_dashboard_from_password
{
    display: flex;
    justify-content: flex-end;
}
.contact_dashboard_from_password span
{
    color: #fff;
}
.contact_dashboard_from_password span:hover{
    cursor: pointer;
    color: #e22024;
}


.submit_button_provider:focus
{
    box-shadow: 0 0 0 0.2rem rgb(20 20 20);
}







@media(max-width:776px)
{
    .provider_sign_in_wrapper
    {   
        margin-top: 100px;
        padding-bottom: unset;
    }
}