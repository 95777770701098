.common-header
{
    padding-top: 80px;
}

.common-header-img
{
 
    height: 400px;
    width: 100%;
    background-position: center;
    background-size: cover;
    position: relative;
}
.common-overlay
{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    height: 400px;
    width: 100%;
    opacity: .3;
}
.common-title {
    color: white;
    font-size: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
}
.common-title span{
    display: block;
    text-align: center;
    font-size: 18px;
    font-weight: initial;
}
.common-home-link a{
    color: #fff;
}


@media(max-width:776px)
{
    .common-title {
        color: white;
    font-size: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    text-align: center;
    line-height: 50px;
    }
}