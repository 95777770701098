/* register_banner_section */
.consumer_banner_section
{
    background-color: #141414;
    background-image: url('../../../assets/dabblefit/consumer/background/background.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    height: 60vh;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-size: cover;
    display: flex;
    align-items: center;
}

.consumer_banner_section_overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #141414;
    width: 100%;
    height: 60vh;
    opacity: 0.9;
}

.consumer_banner_content h1 {
    color: #fff;
    letter-spacing: 20px;
    text-align: center;
    font-size: 50px;
}
  .consumer_banner_content span{
    color: #e22024;
    font-weight: 800;
  }

/* Consumer service */

.consumer_service_section
{
    padding-top: 70px;
}
.consumer_service_title h6 {
    color: #fff;
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    line-height: 35px;
}
.consumer_service_title span{
    color: #e22024;
}


/* Consumer service */

/* consumer_cards_section */




.consumer_cards_section
{
    padding-top: 70px;
}
.consumer_card_wrapper
{
    background-color: #1b1515;
    padding: 25px 20px;
    border-radius: 90px;
}


.consumer_card_images
{
    height: 200px;
    width: 200px;
}
.consumer_card_images img
{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.consumer_card_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px;
}

.consumer_card_container::after 
{
    position: absolute;
    content: '';
    background: linear-gradient(to bottom, #1b1515, #fff, #1b1515);
    right: 0;
    width: 2px;
    height: 75%;
    top: 40px;
}
.consumer_card_container1::after 
{
    position: absolute;
    content: '';
    background: linear-gradient(to bottom, #1b1515, #fff, #1b1515);
    right: 0;
    width: 0px !important;
    height: 75%;
    top: 40px;
}
.consumer_card_container h6 {
    color: #ffffff;
    text-align: center;
    letter-spacing: 1px;
    padding-bottom: 20px;
}



.consumer_card_title
{
    position: relative;
    width: 50%;
}
.consumer_card_container h6::after {
    position: absolute;
    content: '';
    background-color: #e22024;
    width: 170px;
    height: 1px;
    top: 50px;
    left: 3px;
}
.consumer_card_description
{
    text-align: center;
}



/* consumer_cards_section */








/* Consumer service */













/* consumer_signup_section */

.consumer_signup_section
{
    padding-top: 70px;
}
.consumer_signup_title h1
{
    color: #e22024;
    letter-spacing: 10px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 40px;
    padding-bottom: 20px;
    text-align: center;
}
.consumer_signup_title span{
    font-weight: normal;
}
.consumer_signup_title p{
    text-align: center;
    color: #fff;
    padding-bottom: 20px;

}

/* consumer_signup_section */







/* consumer_how_section */


.consumer_how_section
{
    padding-top: 70px;
}


/* consumer_how_section */

  @media (max-width: 776px)

  {
    .consumer_banner_content h1 {
        font-size: 30px;
        letter-spacing: 2px;
    }
    .consumer_service_title h6 {
        font-size: 16px;
        line-height: 30px;
    }
   
.consumer_card_container::after 
{

    width: 0px;
}
.consumer_card_container1::after 
{
    width: 0px;
  
    
}
    
    

  }



