/* background image */

.softwarebackground
{
    background-image: url('../../../assets/vimkes/softwaredevelopement/bg/software.jpg');
}

.software-section-intro
{
    padding-top: 70px;
    padding-bottom: 20px;
}

@media(max-width:776px)
{
    .software-section-intro
    {
    padding-top: 0px;
    }
    .software-section-intro .about-intro-image
    {
        margin-top: 40px;
    }
}


/* background image */



/* skillset-section-software */

.skillset-section-software
{
    padding-top: 70px;
    padding-bottom: 70px;
    /* background-color: #F1F6FC; */
}


/* skillset-section-software */

/* software-services-card */
.software-service-sectiond
{
    padding-top: 70px;
}
.software-services-card
{
    margin: 0px 0px 20px 0px;
    padding: 20px;
    background-color: #F1F6FC;
    border-radius: 4px 4px 4px 4px;
    display: flex;
}
.software-services-card-img img{
    width: 100px;
    padding: 5px 5px 0px 0px;
}
.software-service-content
{
    padding-left: 20px;
}

.software-services-title
{
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    color: #101010;
}
.software-services-desc{
    color: #444444;
}


/* software-services-card */